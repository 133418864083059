var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottomnav",attrs:{"value":_vm.current}},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"estate","fill":_vm.setColor('dashboard')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t("MENU.home"))+" ")])])],1)])]}}])}),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/appointment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"calender","fill":_vm.setColor('doc_appointment')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t('MENU.appointment'))+" ")])])],1)])]}}],null,false,3043898663)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/referral"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"comment-redo","fill":_vm.setColor('second_opinion')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t('MENU.second_opinion'))+" ")])])],1)])]}}],null,false,2812492036)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/schedule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"clock","fill":_vm.setColor('schedule')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t('MENU.schedule'))+" ")])])],1)])]}}],null,false,4190776974)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"comment-alt-question","fill":_vm.setColor('questions')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t('MENU.questions'))+" ")])])],1)])]}}],null,false,1005266658)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":'/medical_file/' + _vm.$root.mf},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"file-medical-alt","fill":_vm.setColor('medical_file')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t("MENU.medical_file"))+" ")])])],1)])]}}],null,false,3696554098)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":"/user/appointment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"calender","fill":_vm.setColor('userappointment')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t("MENU.appointment"))+" ")])])],1)])]}}],null,false,2669446465)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":"/user/questions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"navitem",on:{"click":navigate}},[_c('div',{staticClass:"px-4 py-2 footerpill",class:isActive ? 'blue lighten-5' : ''},[_c('unicon',{attrs:{"name":"comment-alt-question","fill":_vm.setColor('userquestions')}}),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],staticClass:"primary--text",staticStyle:{"align-self":"center","white-space":"nowrap"},style:(isActive ? 'padding-left: 5px;' : 'padding:0px')},[_vm._v(" "+_vm._s(_vm.$t("MENU.questions"))+" ")])])],1)])]}}],null,false,2716437427)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }