<template>
  <div :value="current" class="bottomnav">
    <router-link to="/dashboard" v-slot="{ navigate, isActive }">
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon name="estate" :fill="setColor('dashboard')"></unicon>
          <v-expand-x-transition>
          <div
          :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t("MENU.home") }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 3"
      to="/doctor/appointment"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon name="calender" :fill="setColor('doc_appointment')"></unicon>
          <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t('MENU.appointment') }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 3"
      to="/doctor/referral"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon name="comment-redo" :fill="setColor('second_opinion')"></unicon>
          <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t('MENU.second_opinion') }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 3"
      to="/doctor/schedule"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon name="clock" :fill="setColor('schedule')"></unicon>
          <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t('MENU.schedule') }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 3"
      to="/doctor/question"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon
          name="comment-alt-question"
          :fill="setColor('questions')"
        ></unicon>
         <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t('MENU.questions') }}
          </div>
         </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 4"
      :to="'/medical_file/' + $root.mf"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon
          name="file-medical-alt"
          :fill="setColor('medical_file')"
        ></unicon>
         <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t("MENU.medical_file") }}
          </div>
         </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 4"
      to="/user/appointment"
      v-slot="{ navigate, isActive }"
    >
      <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon name="calender" :fill="setColor('userappointment')"></unicon>
          <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t("MENU.appointment") }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="role == 4"
      to="/user/questions"
      v-slot="{ navigate, isActive }"
    >
       <div class="navitem" @click="navigate">
        <div
          class="px-4 py-2 footerpill"
          :class="isActive ? 'blue lighten-5' : ''"
        >
          <unicon
          name="comment-alt-question"
          :fill="setColor('userquestions')"
        ></unicon>
          <v-expand-x-transition>
          <div
             :style="isActive ? 'padding-left: 5px;' : 'padding:0px'"
            style=" align-self: center;white-space: nowrap;"
            class="primary--text"
            v-show="isActive"
          >
            {{ $t("MENU.questions") }}
          </div>
          </v-expand-x-transition>
        </div>
      </div>
    </router-link>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Aside",
  data() {
    return {
      role: 0,
      enableButton: false,
      current: 7,
      pagelist: [
        { link: "/dashboard", order: 0 },
        { link: "/doctor/appointment", order: 1 },
        { link: "/doctor/referral", order: 2 },
        { link: "/doctor/schedule", order: 3 },
        { link: "/doctor/question", order: 4 },
        { link: "/medical_file/", order: 1 },
        { link: "/user/appointment", order: 2 },
        { link: "/user/questions", order: 3 },
      ],
    };
  },
  components: {},

  watch: {
    $route(to) {
      console.log("page name", to.name);
      this.$root.currentpage = to.name;
    },
  },
  mounted() {
    this.role = JSON.parse(localStorage.userInfo).role;
    this.userID = JSON.parse(localStorage.userInfo).id;

    /*this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });*/
    //console.log("going to load medical file id now");
    //this.loadMedicalID();
  },
  methods: {
    setColor(p) {
      if (this.$root.currentpage == p) {
        return "#0971CE";
      } else {
        return "#9E9E9E";
      }
    },
    async loadMedicalID() {
      if (this.$root.mf == 0) {
        //console.log('getting mf id')
        ApiService.get(
          "items",
          "medical_file" + "?filter[owner]=" + this.userID
        )
          .then(({ data }) => {
            //this.medical_file_id = data.data[0].id;
            //console.log(this.medical_file_id);
            this.$root.mf = data.data[0].id;
          })
          .catch((error) => {
            this.error = error;
          });
      }
    },
  },
};
</script>
<style scoped>
.bottomnav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: center;
  align-content: space-evenly;
  height: 56px;
  width: 100%;
}
.navitem {
  flex-grow: 1;
  min-width: 50px;
  display: flex;
  justify-content: center;
}
</style>